@layer modules, ui, base;
@layer base {
  .Socials_main___iTsF {
  display: flex;
  position: relative;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.Socials_item__CElhu {
  flex: 1 0 auto;
  margin: 0.5rem;
  text-align: center;
}

.Socials_icon__SS3zT {
  width: auto;
  height: 20px;
}

}
